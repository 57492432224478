div.pagination-bottom {
  border-top: 1px solid #e9e8e8;

  div {
    padding: 7px 0;
    text-align: center;

    > div {
      align-self: center;
    }
  }
}

.ReactTable {
  .rt-thead .rt-tr .rt-th {
    padding: 12 6;
    color: white;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }

  .rt-tbody {
    overflow: hidden;

    .rt-tr {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
}
